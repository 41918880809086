import { Observable, UnaryFunction, distinctUntilChanged, map, pipe } from 'rxjs';

/**
 * RXJS Operator function which returns Observable<T[selector]> when it has changed
 * @param selector - (state: T) => R.
 * @return Observable<T[selector]>
 */
export function select<T,R>(selector: (state: T) => R): UnaryFunction<Observable<T>, Observable<R>> {
  return pipe(
    map(state => selector(state)),
    distinctUntilChanged()
  );
}
