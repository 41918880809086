import { BehaviorSubject, Observable } from 'rxjs';
import { Reducer } from './reducer';

export abstract class Store<T> {
  readonly state$: Observable<T>;
  private readonly _state$: BehaviorSubject<T>;
  get state() { return this._state$.getValue(); }

  constructor(initialState: T = undefined) {
    this._state$ = new BehaviorSubject(initialState);
    this.state$ = this._state$.asObservable();
  }

  protected update(...reducers: Reducer<T>[]): void {
    const updatedState = reducers.reduce((x, f) => f(x), this.state);
    const frozen = Object.freeze(updatedState); // this actually should be deep freeze

    if (updatedState === this.state) {
      return;
    }

    this._state$.next(frozen);
  }
}
